import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ForgeViewer from '@/components/autodeskForge/ForgeViewer.vue';
import TreeItem from '@/components/autodeskForge/AutoDeskHubs/TreeItem.vue';
var AutodeskHubs = (function (_super) {
    __extends(AutodeskHubs, _super);
    function AutodeskHubs() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hubNodes = [];
        _this.urn = '';
        _this.selectedModel = null;
        _this.error = null;
        return _this;
    }
    AutodeskHubs.prototype.mounted = function () {
        this.getNodeChildren({ id: '#' });
    };
    AutodeskHubs.prototype.autodeskLogin = function () {
        var routeData = this.$router.resolve({ name: 'CallbackHandler' });
        window.open(routeData.href, 'Login with Autodesk', 'height=585,width=400');
    };
    AutodeskHubs.prototype.modelSelected = function (node) {
        this.selectedModel = node;
        var urn = node.id.split('|')[1];
        this.urn = urn;
        this.$emit('selected', urn || node.id, node);
    };
    AutodeskHubs.prototype.nodeClicked = function (node) {
        if (node.children)
            this.getNodeChildren(node);
    };
    AutodeskHubs.prototype.getNodeChildren = function (node) {
        var _this = this;
        console.log('getNodeChildren -> node', node);
        var getNode = this.$functions.httpsCallable('dataManagement');
        getNode({ id: node.id })
            .then(function (result) {
            console.log('getNodeChildren -> result', result);
            result.data.forEach(function (value) {
                console.log('getNodeChildren -> value', value);
                _this.hubNodes.push(value);
            });
        })
            .catch(function (err) {
            console.log('getNodeChildren -> err', err);
            _this.error = err;
        });
    };
    AutodeskHubs = __decorate([
        Component({
            components: { TreeItem: TreeItem, ForgeViewer: ForgeViewer }
        })
    ], AutodeskHubs);
    return AutodeskHubs;
}(Vue));
export default AutodeskHubs;
