var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "tree-item" }, [
    _c(
      "div",
      { staticClass: "item-content", class: { bold: true } },
      [
        _c(
          "b-button",
          {
            staticClass: "btn-menu",
            attrs: {
              "icon-left": this.types[_vm.item.type].icon,
              type: "is-text"
            },
            on: { click: _vm.toggle }
          },
          [
            _vm._v(_vm._s(_vm.item.text)),
            _vm.isLoading
              ? _c("b-icon", {
                  staticClass: "fa-spin menu-spinner fast-spin",
                  attrs: { icon: "spinner" }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _vm.isFolder
      ? _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen,
                expression: "isOpen"
              }
            ]
          },
          [
            !_vm.isLoading && _vm.children.length === 0
              ? _c(
                  "li",
                  [
                    _c(
                      "b-button",
                      { attrs: { type: "is-text", disabled: "" } },
                      [_vm._v("No items in this folder")]
                    )
                  ],
                  1
                )
              : !_vm.isLoading && _vm.error !== null
              ? _c(
                  "li",
                  [
                    _c(
                      "b-button",
                      { attrs: { type: "is-text", disabled: "" } },
                      [_vm._v(_vm._s(_vm.error.message))]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._l(_vm.children, function(child, index) {
              return _c("tree-item", {
                key: index,
                staticClass: "item",
                attrs: { item: child },
                on: { selected: _vm.onTreeItemSelected }
              })
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }