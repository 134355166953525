var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hubs" }, [
    _vm.error
      ? _c(
          "div",
          { staticClass: "error-container content" },
          [
            _c("b-message", { attrs: { type: "is-danger" } }, [
              _c("p", [
                _vm._v(
                  "There was an error retrieving your BIM360 data. If you created your account using the 'Login with Autodesk' option, this can be fixed by"
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$auth.signOut()
                      }
                    }
                  },
                  [_vm._v("Logging out")]
                ),
                _vm._v(
                  "of BIM Meet and logging in again. This will refresh your autodesk credentials."
                )
              ]),
              _c("li", [
                _vm._v("Provision the BIMMeet app for your Account"),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://forge.autodesk.com/blog/bim-360-docs-provisioning-forge-apps"
                    }
                  },
                  [_vm._v("(instructions here)")]
                )
              ]),
              _c("p")
            ])
          ],
          1
        )
      : _c("div", { staticClass: "columns is-marginless" }, [
          _c(
            "div",
            { staticClass: "button-container column" },
            [
              _c(
                "b-field",
                [
                  _vm.selectedModel
                    ? _c("b-input", {
                        attrs: {
                          value: _vm.selectedModel.text,
                          placeholder: "No model selected.",
                          disabled: ""
                        }
                      })
                    : _c("b-input", {
                        attrs: {
                          value: "No model selected yet.",
                          placeholder: "No model selected.",
                          disabled: ""
                        }
                      })
                ],
                1
              ),
              _c("div", { staticClass: "hubs-container" }, [
                _c(
                  "ul",
                  _vm._l(_vm.hubNodes, function(child, index) {
                    return _c("TreeItem", {
                      key: index,
                      staticClass: "item",
                      attrs: { item: child },
                      on: { selected: _vm.modelSelected }
                    })
                  }),
                  1
                )
              ])
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }