import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var TreeItem = (function (_super) {
    __extends(TreeItem, _super);
    function TreeItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.children = [];
        _this.isOpen = false;
        _this.error = null;
        _this.isLoading = false;
        _this.types = {
            default: { 'icon': 'question' },
            '#': { 'icon': 'user' },
            hubs: { 'icon': 'project-diagram' },
            personalHub: { 'icon': 'user' },
            bim360Hubs: { 'icon': 'building' },
            bim360projects: { 'icon': 'project-diagram' },
            a360projects: { 'icon': 'project-diagram' },
            folders: { 'icon': 'folder' },
            items: { 'icon': 'file' },
            bim360documents: { 'icon': 'file' },
            versions: { 'icon': 'clock' },
            unsupported: { 'icon': 'ban' }
        };
        return _this;
    }
    TreeItem_1 = TreeItem;
    Object.defineProperty(TreeItem.prototype, "isFolder", {
        get: function () {
            return this.item.children;
        },
        enumerable: false,
        configurable: true
    });
    TreeItem.prototype.onChildrenReady = function (children) {
        console.log('on children ready called in node', this.item, children);
    };
    TreeItem.prototype.onTreeItemSelected = function (event) {
        console.log('TreeItem -> onTreeItemSelected -> event', event);
        Object.assign(event.attributes, this.item.attributes);
        this.$emit('selected', event);
    };
    TreeItem.prototype.toggle = function () {
        var _this = this;
        if (this.isFolder) {
            console.log('TreeItem -> toggle -> isFolder', this.isFolder);
            if (!this.isOpen && this.children.length == 0 && !this.isLoading) {
                this.isLoading = true;
                var getNode = this.$functions.httpsCallable('dataManagement');
                getNode({ id: this.item.id })
                    .then(function (result) {
                    result.data.forEach(function (value) {
                        _this.children.push(value);
                    });
                    _this.isOpen = true;
                    _this.isLoading = false;
                })
                    .catch(function (err) {
                    console.error(err);
                    _this.isLoading = false;
                    _this.error = err;
                });
            }
            else {
                this.isOpen = !this.isOpen;
            }
        }
        else {
            this.$emit('selected', this.item);
        }
    };
    var TreeItem_1;
    __decorate([
        Prop()
    ], TreeItem.prototype, "item", void 0);
    TreeItem = TreeItem_1 = __decorate([
        Component({
            components: { TreeItem: TreeItem_1 },
            name: 'tree-item'
        })
    ], TreeItem);
    return TreeItem;
}(Vue));
export default TreeItem;
