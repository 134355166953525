import { render, staticRenderFns } from "./TreeItem.vue?vue&type=template&id=656f472a&scoped=true&lang=pug&"
import script from "./TreeItem.vue?vue&type=script&lang=ts&"
export * from "./TreeItem.vue?vue&type=script&lang=ts&"
import style0 from "./TreeItem.vue?vue&type=style&index=0&id=656f472a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "656f472a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Apogea\\Projects\\bim-meet\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('656f472a')) {
      api.createRecord('656f472a', component.options)
    } else {
      api.reload('656f472a', component.options)
    }
    module.hot.accept("./TreeItem.vue?vue&type=template&id=656f472a&scoped=true&lang=pug&", function () {
      api.rerender('656f472a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/autodeskForge/AutoDeskHubs/TreeItem.vue"
export default component.exports